.post-content {
  table {
    border-collapse: collapse;
    margin: 25px auto;
    font-size: 0.9em;
    min-width: 400px;
    max-width: 100%;

    th,
    td {
      padding: 12px 15px;
      // Default
      border: 1px solid $light-table-color;
      
      @media (prefers-color-scheme: dark) {
        border: 1px solid $dark-table-color;
      }
    
      @media (prefers-color-scheme: light) {
        border: 1px solid $light-table-color;
      }
    
      [data-theme=dark] & {
        border: 1px solid $dark-table-color;
      }
    
      [data-theme=light] & {
        border: 1px solid $light-table-color;
      }
    }

    thead {
      tr {
        text-align: left;
        // Default
        background-color: $light-table-color;
        color: $light-color;

        @media (prefers-color-scheme: dark) {
          background-color: $dark-table-color;
          color: $dark-color;
        }
      
        @media (prefers-color-scheme: light) {
          background-color: $light-table-color;
          color: $light-color;
        }
      
        [data-theme=dark] & {
          background-color: $dark-table-color;
          color: $dark-color;
        }
      
        [data-theme=light] & {
          background-color: $light-table-color;
          color: $light-color;
        }
      }
    }

    tbody {
      tr {
        // Default
        border: 1px solid $light-table-color;
        
        @media (prefers-color-scheme: dark) {
          border: 1px solid $dark-table-color;
        }
      
        @media (prefers-color-scheme: light) {
          border: 1px solid $light-table-color;
        }
      
        [data-theme=dark] & {
          border: 1px solid $dark-table-color;
        }
      
        [data-theme=light] & {
          border: 1px solid $light-table-color;
        }
      }
    }
  }
}
